import Swiper from "swiper";
import $ from "jquery";
import { Grid, Navigation, Thumbs } from "swiper/modules";

$(document).ready(function () {
  $(".product-gallery").each(function () {
    const self = $(this);
    const mainSwiper = self.find(".product-gallery-main");
    const thumbsSwiper = self.find(".product-gallery-thumbs");

    let thumbs = null;

    if (thumbsSwiper.length > 0) {
      thumbs = new Swiper(thumbsSwiper[0], {
        modules: [Grid],
        spaceBetween: 8,
        slidesPerView: 4,
        grid: {
          fill: "row",
          rows: 3
        },
        watchSlidesProgress: true
      });
    }

    const swiper = new Swiper(mainSwiper[0], {
      modules: [Navigation, Thumbs],
      loop: false,
      slidesPerView: 1,
      initialSlide: mainSwiper.data("initial-slide"),
      watchSlidesProgress: true, // https://github.com/nolimits4web/swiper/issues/5437
      ...(thumbs && {
        thumbs: {
          swiper: thumbs,
          autoScrollOffset: 1
        }
      })
    });

    document.addEventListener(
      "eguma.product.onChangeVariant",
      function (event) {
        if (event.detail.variant.slideIndex === -1) {
          return;
        }
        swiper.slideTo(event.detail.variant.slideIndex, 0, false);
      }
    );
  });
});
